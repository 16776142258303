import React from 'react';
import styled, { css } from 'styled-components';

import { MenuItemType } from '@tager/web-modules';

import AccordionMenu from '@/components/AccordionMenu';
import { media } from '@/utils/mixin';

import FooterMenu from './FooterMenu';

type Props = {
  navbarList: Array<MenuItemType>;
  isFlex?: boolean;
};

function FooterNavbarMobile({ navbarList, isFlex }: Props) {
  return (
    <NavbarList>
      {navbarList.map(({ label, children }, index) => {
        return (
          <NavbarListCell key={index}>
            <AccordionMenu title={label}>
              <FooterMenu menuList={children} isFlex={isFlex} />
            </AccordionMenu>
          </NavbarListCell>
        );
      })}
    </NavbarList>
  );
}

const NavbarList = styled.div`
  margin: -36px 0;

  ${media.mobile(css`
    margin: 0;
  `)}
`;

const NavbarListCell = styled.div`
  margin: 36px 0;

  ${media.mobile(css`
    margin: 0 0 37px;

    &:last-child {
      margin-bottom: 0;
    }
  `)}
`;

export default FooterNavbarMobile;
