import React, { useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import { useRouter } from 'next/router';

import { MenuItemType } from '@tager/web-modules';

import DropDownSelect from '@/components/DropDownSelect';
import { OptionType } from '@/components/DropDownSelect/DropDownSelect';
import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';

interface Props {
  storeList: Array<MenuItemType>;
  activeOption: OptionType;
}

function StoreSelect({ storeList, activeOption }: Props) {
  const router = useRouter();
  const [selectedStore, setSelectedStore] = useState<OptionType>(activeOption);

  const [storeOptions, setStoreOptions] = useState<Array<OptionType>>(
    createStoreOptions(storeList)
  );

  function createStoreOptions(storeList: Array<MenuItemType>) {
    const optionList: Array<OptionType> = [];

    storeList.forEach((storeItem) => {
      let newStoreItem: OptionType = { label: '', value: '' };

      newStoreItem.label = storeItem.label ?? '';
      newStoreItem.value = storeItem.label ?? '';
      optionList.push(newStoreItem);
    });

    return optionList;
  }

  let sortStoreValue = useMemo<OptionType>(
    () =>
      storeOptions.find((option) => option.value === selectedStore?.value) ??
      storeOptions[0],
    [selectedStore, storeOptions]
  );

  function handlesStoreChange(selectedOption: OptionType | null): void {
    if (selectedOption) {
      setSelectedStore(selectedOption);

      storeList.map((storeItem) => {
        if (storeItem.label === selectedOption.value) {
          router.push(`${storeItem.link}`);
        }
      });
    }
  }

  return (
    <Component>
      <DropDownSelect
        placeholder="Выбрать..."
        options={storeOptions}
        value={sortStoreValue}
        onChange={handlesStoreChange}
        isHeaderSelect={true}
      />
    </Component>
  );
}

const Component = styled.div`
  margin: 0 24px 0 -30px;
  background: ${colors.yellow200};
  padding: 0 38px;

  ${media.laptop(css`
    padding: 0 44px 0 38px;
  `)}
`;

export default StoreSelect;
