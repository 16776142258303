import React from 'react';
import styled, { css } from 'styled-components';

import { nl2br, Nullable } from '@tager/web-core';

import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';

type Props = {
  address: Nullable<string>;
};

function InformationBlock({ address }: Props) {
  return (
    <InformationContent>
      <Address>{nl2br(address)}</Address>
    </InformationContent>
  );
}

const InformationContent = styled.div`
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  color: ${colors.grayLight};

  ${media.laptop(css`
    font-size: 16px;
    line-height: 24px;
  `)}

  ${media.tabletSmall(css`
    font-size: 16px;
    line-height: 22px;
  `)}

  ${media.mobile(css`
    font-size: 12px;
    line-height: 20px;
  `)}
`;

const Address = styled.div``;

export default InformationBlock;
