import React from 'react';
import styled, { css } from 'styled-components';

import { getSettingValueByKey } from '@tager/web-modules';
import { useMedia } from '@tager/web-core';

import { breakpoints, colors } from '@/constants/theme';
import { media } from '@/utils/mixin';
import { useTypedSelector } from '@/store/store';
import { selectMenuItemListByAlias } from '@/store/reducers/tager/menus';
import { selectSettingItemList } from '@/store/reducers/tager/settings';
import { useLayout } from '@/components/Layout/Layout.hooks';
import { StringField } from '@/typings/model';

import FooterBlock from './components/FooterBlock';
import FooterNavbar from './components/FooterNavbar';
import FooterNavbarMobile from './components/FooterNavbarMobile';
import SocialBlock from './components/SocialBlock';
import AppBlock from './components/AppBlock';
import InformationBlock from './components/InformationBlock';
import FooterBottomMenu from './components/FooterBottomMenu';

interface Props {
  onCookiesClick?: () => void;
}

type TMenuItem = { label: string; link: string };

function Footer({ onCookiesClick }: Props) {
  const { footerInformation, themePage } = useLayout();
  const footerMenu =
    useTypedSelector((state) => selectMenuItemListByAlias(state, 'footer')) ??
    [];

  const settingsList = useTypedSelector(selectSettingItemList);

  const copyright = getSettingValueByKey(settingsList, 'FOOTER_COPYRIGHT');
  const addressContent = getSettingValueByKey(
    settingsList,
    'FOOTER_ADDRESS_CONTENT'
  );
  const socialTitle = getSettingValueByKey(settingsList, 'FOOTER_SOCIAL_TITLE');
  const mobileTitle = getSettingValueByKey(settingsList, 'FOOTER_MOBILE_TITLE');
  const addressTitle = getSettingValueByKey(
    settingsList,
    'FOOTER_ADDRESS_TITLE'
  );

  const footerBottomMenu = getSettingValueByKey(
    settingsList,
    'FOOTER_BOTTOM_MENU'
  );

  const isSmallTablet = useMedia(
    `(max-width: ${breakpoints.tabletLarge - 1}px)`
  );

  return (
    <FooterContainer>
      <FooterInner>
        <FooterLeft>
          <FooterTop>
            {!isSmallTablet ? (
              <FooterNavbar navbarList={footerMenu} />
            ) : (
              <FooterNavbarMobile navbarList={footerMenu} isFlex={true} />
            )}
          </FooterTop>
          <FooterBottom themePage={themePage}>
            <FooterBottomMenu
              onCookiesClick={onCookiesClick}
              themePage={themePage}
              menu={footerBottomMenu as unknown as TMenuItem[]}
            />
            <FooterCopyright>{copyright}</FooterCopyright>
          </FooterBottom>
        </FooterLeft>
        <FooterRight>
          <FooterBlock title={socialTitle}>
            <SocialBlock />
          </FooterBlock>
          <FooterBlock title={mobileTitle}>
            <AppBlock />
          </FooterBlock>
          {footerInformation ? (
            <FooterBlock title={addressTitle}>
              <InformationBlock address={footerInformation} />
            </FooterBlock>
          ) : (
            <FooterBlock title={addressTitle}>
              <InformationBlock address={addressContent} />
            </FooterBlock>
          )}
        </FooterRight>
      </FooterInner>
    </FooterContainer>
  );
}

const FooterContainer = styled.footer`
  background: ${colors.black};
  display: flex;
  overflow: hidden;
  z-index: 25;
  margin-top: -180px;
  clip-path: polygon(0% 180px, 100% 90px, 100% 100%, 0% 100%);

  ${media.laptop(css`
    margin-top: -120px;
    clip-path: polygon(0% 120px, 100% 60px, 100% 100%, 0% 100%);
  `)}

  ${media.tablet(css`
    margin-top: -36px;
    clip-path: polygon(0% 36px, 100% 0, 100% 100%, 0 100%);
  `)}
`;

const FooterInner = styled.div`
  display: flex;
  max-width: 1920px;
  width: 100%;
  margin: 0 auto;
`;

const FooterLeft = styled.div`
  flex: 1 1 auto;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const FooterRight = styled.div`
  flex: 0 0 460px;
  padding: 300px 60px 20px;
  background: ${colors.white10};
  position: relative;

  ${media.laptop(css`
    flex: 0 0 308px;
    padding: 200px 40px 14px;
  `)}

  ${media.tablet(css`
    padding: 120px 40px 14px;
  `)}

  ${media.tabletSmall(css`
    flex: 0 0 386px;
    padding: 65px 20px 14px;

    display: flex;
    flex-direction: column;
    justify-content: center;
  `)}

  &:before {
    position: absolute;
    content: '';
    top: 0;
    right: -4000px;
    height: 100%;
    width: 4000px;
    background: ${colors.white10};
  }
`;

const FooterTop = styled.div`
  padding: 300px 130px 40px 60px;

  @media (max-width: 1900px) {
    padding: 300px 60px 40px;
  }

  ${media.laptop(css`
    padding: 200px 60px 40px 40px;
  `)}

  ${media.tablet(css`
    padding: 120px 20px 40px 30px;
  `)}
  
  ${media.tabletSmall(css`
    padding: 65px 20px 20px 20px;
    margin-bottom: 40px;
    max-height: 375px;
    overflow-y: auto;
  `)}
`;

const FooterBottom = styled.div<{ themePage: StringField }>`
  position: relative;
  padding: 30px 60px;
  background: ${colors.black};

  ${media.laptop(css`
    padding: 32px 40px;
  `)}

  ${media.tablet(css`
    padding: 32px 30px;
  `)}
  
  ${media.tabletLarge(css`
    padding: 18px 40px 18px 30px;
  `)}

  ${media.mobileLarge(css`
    padding: 20px;
  `)}

  ${media.tabletSmall(css`
    padding: 20px;
  `)}

  &::after {
    width: calc(100% - 120px);
    display: block;
    position: absolute;
    top: 0;
    content: '';
    height: 1px;
    background: ${colors.gray100};

    ${media.tabletLarge(css`
      width: calc(100% - 40px);
    `)}
  }

  &::before {
    position: absolute;
    content: '';
    top: 0;
    left: -4000px;
    height: 100%;
    width: 4000px;
    background: ${(props) =>
      props.themePage ? `${props.themePage}` : `${colors.orange}`};
  }
`;

const FooterCopyright = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  color: ${colors.gray90};

  ${media.tabletLarge(css`
    font-size: 14px;
    line-height: 26px;
  `)}

  ${media.mobileLarge(css`
    font-size: 12px;
    line-height: 14px;
  `)}
`;

export default Footer;
