import styled, { keyframes } from 'styled-components';

export const fadeOutCss = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

export const bounceCss = keyframes`
  0% {
    max-width: 0;
}

  100% {
    max-width: 100%;
}
`;

export const Inner = styled.div`
  position: relative;
  width: 240px;
  height: 240px;
  user-select: none;
  text-align: center;
`;

export const WrapperIconLogo = styled.div`
  svg {
    width: 128px;
    height: 128px;
  }
`;

export const Line = styled.div`
  margin: 40px auto 0;
  height: 4px;
  width: 160px;
  background: #2d2d2d;
  position: relative;
`;

export const LineFill = styled.span`
  position: absolute;
  content: '';
  top: 0;
  bottom: 0;
  left: 0;
  max-width: 0;
  width: 100%;
  background: #f9683a;
  animation: ${bounceCss} 7s linear infinite;
`;

export const PreloaderText = styled.div`
  display: inline-block;
  margin-top: 24px;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: rgba(255, 255, 255, 0.4);
  position: relative;
  padding-right: 2px;
`;

export const Overlay = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #020202;
  opacity: 1;
  will-change: opacity;
  z-index: 10000;
  overflow: hidden;

  &.fade-out {
    animation: ${fadeOutCss} 1s ease-in-out forwards;
  }
`;
