import React, { useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';

import { MenuItemType } from '@tager/web-modules';
import { Nullable, scroller } from '@tager/web-core';

import { media } from '@/utils/mixin';
import { colors } from '@/constants/theme';
import AccountBtn from '@/components/Layout/components/Header/components/AccountBtn';
import HeaderNav from '@/components/Layout/components/Header/components/HeaderNav';
import Stores from '@/components/Layout/components/Header/components/Stores';

interface Props {
  headerMenuTop: Array<MenuItemType>;
  headerMenuMain: Array<MenuItemType>;
  isShowMenu: boolean;
  setShowMobileMenu: (value: boolean) => void;
  onCookiesClick?: () => void;
  searchActive: boolean;
  accountBtnLabel: Nullable<string>;
  accountBtnUrl: Nullable<string>;
}

function MobileMenu({
  headerMenuTop,
  headerMenuMain,
  isShowMenu,
  setShowMobileMenu,
  onCookiesClick,
  searchActive,
  accountBtnUrl,
  accountBtnLabel,
}: Props) {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isShowMenu) {
      scroller.lock(ref.current);
      document.body.style.overflow = 'hidden';
    } else {
      scroller.unlockAll();
      document.body.style.overflow = 'auto';
    }

    return () => {
      scroller.unlockAll();
      document.body.style.overflow = 'auto';
    };
  }, [isShowMenu, searchActive]);

  return (
    <Component ref={ref} isShowMenu={isShowMenu}>
      <Inner>
        <Top>
          {headerMenuMain && headerMenuMain.length > 0 && (
            <Stores
              storeList={headerMenuMain}
              setShowMobileMenu={setShowMobileMenu}
            />
          )}
        </Top>
        {accountBtnLabel && accountBtnUrl && (
          <Center>
            <AccountBtn label={accountBtnLabel} url={accountBtnUrl} />
          </Center>
        )}
        <Bottom>
          {headerMenuTop && headerMenuTop.length > 0 && (
            <HeaderNav
              menuItemList={headerMenuTop}
              setShowMobileMenu={setShowMobileMenu}
              onCookiesClick={onCookiesClick}
            />
          )}
        </Bottom>
      </Inner>
    </Component>
  );
}

const Component = styled.div<{ isShowMenu: boolean }>`
  margin-left: 0;
  position: fixed;
  top: 70px;
  height: calc(var(--vh, 1vh) * 100 - 70px);
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  transition: transform 0.3s ease;
  background: ${colors.black};
  padding: 64px 0 30px;
  z-index: 10;

  transform: ${(props) =>
    props.isShowMenu ? 'translateX(0)' : 'translateX(100%)'};

  ${media.laptopUp(css`
    display: none;
  `)}

  ${media.mobile(css`
    top: 56px;
    height: calc(100vh - 56px);
    padding: 64px 0 30px;
  `)};
`;

const Inner = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  ${media.tablet(css`
    padding: 0 40px;
  `)}

  ${media.mobile(css`
    padding: 0 20px;
  `)}
`;

const Top = styled.div`
  margin-bottom: 50px;

  ${media.mobile(css`
    margin-bottom: 32px;
  `)}
`;

const Center = styled.div`
  margin-bottom: 50px;

  ${media.mobile(css`
    margin-bottom: 32px;
  `)}
`;

const Bottom = styled.div``;

export default MobileMenu;
