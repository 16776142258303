import React from 'react';
import styled, { css } from 'styled-components';

import { getSettingValueByKey } from '@tager/web-modules';

import Picture from '@/components/Picture';
import { media } from '@/utils/mixin';
import { useTypedSelector } from '@/store/store';
import { selectSettingItemList } from '@/store/reducers/tager/settings';
import appStore from '@/assets/images/footer/app/app-store.png';
import appStore2x from '@/assets/images/footer/app/app-store@2x.png';
import appStoreWebp from '@/assets/images/footer/app/app-store.webp';
import appStoreWebp2x from '@/assets/images/footer/app/app-store@2x.webp';
import googlePlay from '@/assets/images/footer/app/google-play.png';
import googlePlay2x from '@/assets/images/footer/app/google-play@2x.png';
import googlePlayWebp from '@/assets/images/footer/app/google-play.webp';
import googlePlayWebp2x from '@/assets/images/footer/app/google-play@2x.webp';

function AppBlock() {
  const settingsList = useTypedSelector(selectSettingItemList);

  const googlePlayUrl = getSettingValueByKey(
    settingsList,
    'FOOTER_MOBILE_GOOGLE_PLAY_URL'
  );
  const appStoreUrl = getSettingValueByKey(
    settingsList,
    'FOOTER_MOBILE_APP_STORE_URL'
  );

  return (
    <AppList>
      {googlePlayUrl ? (
        <AppItem>
          <AppItemBackground>
            <Picture
              mobileSmall={{
                src: googlePlay,
                src2x: googlePlay2x,
                webp: googlePlayWebp,
                webp2x: googlePlayWebp2x,
              }}
            />
          </AppItemBackground>

          <AppLink href={googlePlayUrl} target="_blank" />
        </AppItem>
      ) : null}

      {appStoreUrl ? (
        <AppItem>
          <AppItemBackground>
            <Picture
              mobileSmall={{
                src: appStore,
                src2x: appStore2x,
                webp: appStoreWebp,
                webp2x: appStoreWebp2x,
              }}
            />
          </AppItemBackground>
          <AppLink href={appStoreUrl} target="_blank" />
        </AppItem>
      ) : null}
    </AppList>
  );
}

const AppList = styled.ul`
  ${media.tabletSmall(css`
    display: flex;
    flex-wrap: wrap;
    margin: -9px;
  `)}

  ${media.mobile(css`
    margin: -5px;
  `)}
`;

const AppItem = styled.li`
  position: relative;
  width: 202px;
  margin-top: 20px;
  transition: all 0.3s linear;
  ${media.laptop(css`
    margin-top: 12px;
    width: 135px;
  `)}

  ${media.tabletSmall(css`
    width: 154px;
    margin: 9px;
  `)}
  
  ${media.mobile(css`
    margin: 5px;
  `)}

  &:first-child {
    margin-top: 0;

    ${media.tabletSmall(css`
      margin-top: 9px;
    `)}

    ${media.mobile(css`
      margin-top: 5px;
    `)}
  }

  &:before {
    display: block;
    content: '';
    padding-bottom: 29.7%;
  }

  &:hover {
    opacity: 0.7;
  }
`;

const AppItemBackground = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  img {
    width: 100%;
    height: 100%;
  }
`;

const AppLink = styled.a`
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
`;

export default AppBlock;
