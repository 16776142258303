import styled, { css } from 'styled-components';

import { CssSnippet } from '@tager/web-components';

import { colors } from '@/constants/theme';

export type ReservedButtonVariant =
  | 'primary'
  | 'contained'
  | 'outlined'
  | 'orange'
  | 'gray'
  | 'yellow'
  | 'red';

export type ButtonVariant = ReservedButtonVariant | CssSnippet;

const variantCssMap: Record<ReservedButtonVariant, CssSnippet> = {
  primary: css`
    background-color: ${colors.black};
    color: ${colors.white};

    &:hover {
      background-color: transparent;
      color: ${colors.black};
    }
    &:active {
      background-color: transparent;
      color: ${colors.black};
    }
  `,
  orange: css`
    background-color: ${colors.orange};
    color: ${colors.white};

    &:hover {
      background-color: ${colors.orangeHover};
    }
    &:active {
      background-color: ${colors.orangeActive};
    }
  `,
  gray: css`
    background-color: ${colors.gray50};
    color: ${colors.black};

    &:hover {
      background-color: ${colors.grayHover};
    }
    &:active {
      background-color: ${colors.grayActive};
    }
  `,
  yellow: css`
    background-color: ${colors.yellow200};
    color: ${colors.black};

    &:hover {
      background-color: ${colors.yellowHover};
    }
    &:active {
      background-color: ${colors.yellowActive};
    }
  `,
  red: css`
    background-color: ${colors.red100};
    color: ${colors.white};

    &:hover {
      background-color: ${colors.redHover};
    }
    &:active {
      background-color: ${colors.redActive};
    }
  `,
  contained: css`
    background-color: #24292e;
    opacity: 0.95;
    color: white;
    border: 1px solid #24292e;

    &:hover {
      background-color: black;
    }
  `,
  outlined: css`
    background-color: white;
    color: #24292e;
    border: 1px solid #24292e;

    &:hover {
      background-color: #24292e;
      opacity: 0.95;
      color: white;
    }
  `,
};

export const StyledButton = styled.button<{ variant?: ButtonVariant }>`
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  text-align: center;
  padding: 10px;
  text-decoration: none;
  transition: all 0.3s ease-in;

  ${(props) =>
    typeof props.variant === 'string'
      ? variantCssMap[props.variant]
      : props.variant};
`;
