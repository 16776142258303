import React from 'react';
import styled, { css } from 'styled-components';
import { useRouter } from 'next/router';

import { MenuItemType } from '@tager/web-modules';
import { useMedia } from '@tager/web-core';

import { breakpoints, colors } from '@/constants/theme';
import { media } from '@/utils/mixin';
import { useLayout } from '@/components/Layout/Layout.hooks';
import Link from '@/components/Link';
import { isLinkActive } from '@/components/Layout/components/Header/utils';

import StoreSelect from './StoreSelect';

type Props = {
  storeList: Array<MenuItemType>;
  setShowMobileMenu: (value: boolean) => void;
};

function Stores({ storeList, setShowMobileMenu }: Props) {
  const { activeStoreName, menuStore } = useLayout();
  const router = useRouter();
  const isTablet = useMedia(`(max-width: ${breakpoints.laptop - 1}px)`);

  function setMenuList(
    storeList: Array<MenuItemType>,
    menuStore: Array<MenuItemType>
  ) {
    if (isTablet) {
      return storeList;
    }

    if (menuStore.length !== 0) {
      return menuStore;
    } else {
      return storeList;
    }
  }

  return (
    <StoreList>
      {menuStore.length !== 0 && !isTablet && (
        <StoreSelect storeList={storeList} activeOption={activeStoreName} />
      )}
      {setMenuList(storeList, menuStore).map((storeItem) => {
        return (
          <StoreItem
            key={storeItem.id}
            isActive={isLinkActive(router.asPath, storeItem.link ?? '')}
            onClick={() => setShowMobileMenu(false)}
          >
            <StoreLink to={storeItem.link ?? '#'}>{storeItem.label}</StoreLink>
          </StoreItem>
        );
      })}
    </StoreList>
  );
}

const StoreList = styled.ul`
  display: flex;
  align-items: center;

  ${media.tablet(css`
    display: block;
  `)}
`;
const StoreItem = styled.li<{ isActive: boolean }>`
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;

  color: ${colors.white};
  transition: all 0.3s ease-in-out;

  ${media.laptop(css`
    font-size: 16px;
    line-height: 24px;
  `)}
  ${media.tablet(css`
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 30px;

    &:last-child {
      margin-bottom: 0;
    }
  `)}
  ${media.mobile(css`
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 24px;
  `)}
  &:hover {
    color: ${colors.orange};
  }

  ${(props) =>
    props.isActive
      ? css`
          transition: all 0s ease-in-out;
          color: ${colors.orange};
          pointer-events: none;
        `
      : ''}
`;

const StoreLink = styled(Link)`
  padding: 18px 8px 18px 8.5px;
  display: block;

  ${media.laptop(css`
    padding: 19px 8px 19px 8.5px;
  `)}

  ${media.tablet(css`
    display: inline-block;
    padding: 0 8px;
  `)}
`;

export default Stores;
