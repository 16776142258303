import { RefObject } from 'react';

import { createContextHookWithProvider } from '@tager/web-core';
import { MenuItemType } from '@tager/web-modules';

import { OptionType } from '@/components/DropDownSelect/DropDownSelect';
import { StringField } from '@/typings/model';

type LayoutContextType = {
  heroRef: RefObject<HTMLDivElement>;
  menuStore: Array<MenuItemType>;
  setMenuStore: (menuList: Array<MenuItemType>) => void;
  activeStoreName: OptionType;
  setActiveStoreName: (storeName: OptionType) => void;
  startPagePathWithFooterInformation: StringField;
  setStartPagePathWithFooterInformation: (text: string) => void;
  footerInformation: StringField;
  setFooterInformation: (text: StringField) => void;
  themePage: StringField;
  setThemePage: (text: StringField) => void;
};

const [useCtx, CtxProvider] =
  createContextHookWithProvider<LayoutContextType>('LayoutProvider');

export const LayoutProvider = CtxProvider;

export function useLayout() {
  return useCtx();
}
