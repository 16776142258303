import { createMediaMixin } from '@tager/web-components';

import { breakpoints } from '@/constants/theme';

/**
 * Reference: https://get.foundation/sites/docs/media-queries.html#working-with-media-queries
 *
 * Media queries name convention: {breakpoint + modifier}
 * Modifiers: "up" | "only" | "down" (default)
 * "down" is default modifier, because we use desktop-first approach
 *
 * Examples:
 *
 * "tablet", "tabletDown" - tablet or smaller
 * "tabletOnly" - only tablet
 * "tabletUp" - tablet or larger
 */
export const media = {
  sizeTabletMax: createMediaMixin({
    max: 1281,
  }),
  sizeTablet: createMediaMixin({
    min: 769,
    max: 1281,
  }),

  sizeSmallMobile: createMediaMixin({
    min: 0,
    max: 361,
  }),

  sizeMobile: createMediaMixin({
    min: 361,
    max: 769,
  }),

  mobileSmall: createMediaMixin({
    max: breakpoints.mobileMedium,
  }),

  custom1: createMediaMixin({
    max: 769,
  }),

  customMinMobile: createMediaMixin({
    max: breakpoints.minMobile,
  }),

  customSmall: createMediaMixin({
    min: breakpoints.minMobile,
    max: breakpoints.custom480,
  }),
  mobileMedium: createMediaMixin({
    max: breakpoints.mobileLarge,
  }),
  mobileMediumOnly: createMediaMixin({
    min: breakpoints.mobileSmall,
    max: breakpoints.mobileLarge,
  }),
  mobileLarge: createMediaMixin({
    max: breakpoints.tabletSmall,
  }),
  mobileLargeOnly: createMediaMixin({
    min: breakpoints.mobileLarge,
    max: breakpoints.tabletSmall,
  }),
  mobile: createMediaMixin({
    max: breakpoints.tabletSmall,
  }),
  tabletSmall: createMediaMixin({
    max: breakpoints.tabletLarge,
  }),
  tabletSmallOnly: createMediaMixin({
    min: breakpoints.tabletSmall,
    max: breakpoints.tabletLarge,
  }),
  tabletLarge: createMediaMixin({
    max: breakpoints.laptop,
  }),
  tabletLargeOnly: createMediaMixin({
    min: breakpoints.tabletLarge,
    max: breakpoints.laptop,
  }),
  tablet: createMediaMixin({
    max: breakpoints.laptop,
  }),
  tabletOnly: createMediaMixin({
    min: breakpoints.tabletSmall,
    max: breakpoints.laptop,
  }),
  tabletUp: createMediaMixin({
    min: breakpoints.laptop,
  }),
  laptop: createMediaMixin({
    max: breakpoints.desktop,
  }),
  laptopOnly: createMediaMixin({
    min: breakpoints.laptop,
    max: breakpoints.desktop,
  }),
  laptopUp: createMediaMixin({
    min: breakpoints.laptop,
  }),
  desktopOnly: createMediaMixin({
    min: breakpoints.desktop,
    max: breakpoints.desktopLarge,
  }),
  desktopLarge: createMediaMixin({
    min: breakpoints.desktopLarge,
  }),
};
