import React, { useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import { getSettingValueByKey } from '@tager/web-modules';

import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';
import { ReactComponent as Logo } from '@/assets/svg/logo.svg';
import { useTypedSelector } from '@/store/store';
import { selectMenuItemListByAlias } from '@/store/reducers/tager/menus';
import Link from '@/components/Link';
import { useLayout } from '@/components/Layout/Layout.hooks';
import { StringField } from '@/typings/model';
import Button from '@/components/Button';
import { ReactComponent as CrossIcon } from '@/assets/svg/cross.svg';
import { ReactComponent as MenuIcon } from '@/assets/svg/menu.svg';
import { ReactComponent as SearchIcon } from '@/assets/svg/search-mobile.svg';
import MobileMenu from '@/components/Layout/components/Header/components/MobileMenu';
import { selectSettingItemList } from '@/store/reducers/tager/settings';

import HeaderNav from './components/HeaderNav';
import AccountBtn from './components/AccountBtn';
import Stores from './components/Stores';
import HeaderSearch from './components/HeaderSearch';
import HeaderSearchMobile from './components/HeaderSearchMobile';

interface Props {
  onCookiesClick?: () => void;
}

function Header({ onCookiesClick }: Props) {
  const headerRef = useRef<HTMLDivElement>(null);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [searchActive, setSearchActive] = useState(false);
  const { themePage } = useLayout();

  const headerMenuTop =
    useTypedSelector((state) => selectMenuItemListByAlias(state, 'top')) ?? [];
  const headerMenuMain =
    useTypedSelector((state) => selectMenuItemListByAlias(state, 'main')) ?? [];

  const settingsList = useTypedSelector(selectSettingItemList);
  const btnLabel = getSettingValueByKey(settingsList, 'HEADER_BUTTON_LABEL');
  const btnUrl = getSettingValueByKey(settingsList, 'HEADER_BUTTON_URL');

  return (
    <HeaderContainer ref={headerRef}>
      <HeaderInner>
        <Left>
          <LogoWrapper themePage={themePage}>
            <LogoLink to="/" />
            <Logo />
          </LogoWrapper>
        </Left>
        <Right>
          <RightContent>
            <RightTop>
              <HeaderNav
                menuItemList={headerMenuTop}
                setShowMobileMenu={(value: boolean) => setShowMobileMenu(value)}
                onCookiesClick={onCookiesClick}
              />
              {btnUrl && btnLabel && (
                <AccountBtn url={btnUrl} label={btnLabel} />
              )}
            </RightTop>
            <RightBottom>
              <Stores
                storeList={headerMenuMain}
                setShowMobileMenu={(value: boolean) => setShowMobileMenu(value)}
              />
              <HeaderSearch />
            </RightBottom>
          </RightContent>
          <RightMobile>
            <SearchToggle
              onClick={() => setSearchActive((prev) => !prev)}
              searchActive={searchActive}
            >
              {searchActive ? <CrossIcon /> : <SearchIcon />}
            </SearchToggle>
            <MobileToggle onClick={() => setShowMobileMenu((prev) => !prev)}>
              {showMobileMenu ? <CrossIcon /> : <MenuIcon />}
            </MobileToggle>
          </RightMobile>
        </Right>
        <HeaderSearchMobile
          searchActive={searchActive}
          setSearchActive={(value: boolean) => setSearchActive(value)}
        />
        <MobileMenu
          isShowMenu={showMobileMenu}
          headerMenuTop={headerMenuTop}
          headerMenuMain={headerMenuMain}
          setShowMobileMenu={(value: boolean) => setShowMobileMenu(value)}
          onCookiesClick={onCookiesClick}
          searchActive={searchActive}
          accountBtnLabel={btnLabel}
          accountBtnUrl={btnUrl}
        />
      </HeaderInner>
    </HeaderContainer>
  );
}

const HeaderContainer = styled.header`
  flex: 0 0 100px;
  background: ${colors.black};
  padding: 0 80px;
  z-index: 100;

  ${media.laptop(css`
    padding: 0;
  `)}

  ${media.tablet(css`
    flex: 0 0 70px;
  `)}

  ${media.mobile(css`
    flex: 0 0 56px;
  `)}
`;

const HeaderInner = styled.div`
  display: flex;
  height: 100%;
  max-width: 1760px;
  margin: 0 auto;

  ${media.tablet(css`
    justify-content: space-between;
    position: relative;
  `)}
`;

const Left = styled.div`
  flex: 0 0 118px;
  position: relative;
  margin-right: 40px;

  ${media.laptop(css`
    margin: 0 30px 0 40px;
  `)}

  ${media.tablet(css`
    flex: 0 0 96px;
    margin: 0 20px;
  `)}

  ${media.mobile(css`
    flex: 0 0 80px;
  `)}
`;

const Right = styled.div`
  flex: 1 1 auto;

  ${media.tablet(css`
    flex: 0 0 140px;
    display: flex;
    justify-content: flex-end;
  `)};
`;

const RightContent = styled.div`
  ${media.tablet(css`
    display: none;
  `)}
`;

const LogoWrapper = styled.div<{ themePage: StringField }>`
  position: absolute;
  content: '';
  top: 0;
  right: 0;
  width: 118px;
  min-height: 128px;
  background: ${(props) =>
    props.themePage ? `${props.themePage}` : `${colors.orange}`};

  padding: 19px 15px;
  transition: all 0.3s ease-in-out;
  z-index: 25;

  ${media.tablet(css`
    width: 96px;
    min-height: 104px;
    padding: 16px 12px 10px;
  `)}
  ${media.mobile(css`
    width: 80px;
    min-height: 88px;
    padding: 17px 12px 12px;
  `)}
  svg {
    width: 88px;
    height: 88px;
    pointer-events: none;

    ${media.tablet(css`
      width: 72px;
      height: 72px;
    `)}

    ${media.mobile(css`
      width: 56px;
      height: 56px;
    `)}
  }

  ${media.laptopUp(css`
    &:hover {
      padding-top: 80px;
    }
  `)}
`;
const LogoLink = styled(Link)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
`;

const RightTop = styled.div`
  display: flex;
  justify-content: space-between;
`;

const RightBottom = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  position: relative;
`;

const RightMobile = styled.div`
  display: none;
  z-index: 40;

  ${media.tablet(css`
    display: flex;
  `)}
`;

const MobileToggle = styled(Button)`
  padding: 0;
  margin: 0;
  background: transparent;
  cursor: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  transition: transform 0.3s linear;
  z-index: 1;

  background: ${colors.orange};
  width: 70px;
  height: 70px;

  ${media.mobile(css`
    width: 56px;
    height: 56px;
  `)}
  svg {
    width: 32px;
    height: 32px;
  }
`;

const SearchToggle = styled(Button)<{ searchActive?: boolean }>`
  padding: 0;
  margin: 0;
  background: transparent;
  cursor: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;

  transition: transform 0.2s linear 0.5s;

  background: ${colors.black500};
  width: 70px;
  height: 70px;

  ${media.mobile(css`
    width: 56px;
    height: 56px;
  `)}
  svg {
    width: 32px;
    height: 32px;
  }

  ${(props) =>
    props.searchActive
      ? css`
          transition: transform 0.2s linear;
          transform: translateX(70px);

          ${media.mobile(css`
            transform: translateX(56px);
          `)}
        `
      : ''}
`;

export default Header;
