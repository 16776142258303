import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';

import useSettingItem from '@/hooks/useSettingItem';
import { ReactComponent as SearchIcon } from '@/assets/svg/search.svg';
import { ReactComponent as CloseIcon } from '@/assets/svg/close-white.svg';
import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';
import { getSearchResult } from '@/services/requests';
import { SearchResultItemType, StringField } from '@/typings/model';
import { queryAdded, selectQuery } from '@/store/reducers/tager/search';
import Link from '@/components/Link';
import { useLayout } from '@/components/Layout/Layout.hooks';

function usePrevious<T>(value: T) {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

function HeaderSearch() {
  const { themePage } = useLayout();

  const [isSearch, setSearch] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const [results, setResults] = useState<Array<SearchResultItemType>>([]);

  const searchBtnRef = useRef<HTMLButtonElement>(null);
  const searchInputRef = useRef<HTMLInputElement>(null);

  const searchHintsString = useSettingItem('SEARCH_HINTS');

  let searchQuery = useSelector(selectQuery);
  const dispatch = useDispatch();
  const router = useRouter();
  const prevPath = usePrevious(router.asPath);

  const searchHintsList = searchHintsString
    ? searchHintsString.split('\n')
    : [];

  function toggleSearch() {
    if (!searchInputRef.current) return;
    setSearch(!isSearch);
    searchInputRef.current.focus();
  }

  function handlerKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.keyCode === 13 && !!searchQuery.trim().length) {
      router.push(`/search?query=${searchQuery}`);
    }
  }

  useEffect(() => {
    if (!searchInputRef.current) return;

    if (isSearch) {
      searchInputRef.current.value = '';
      setSearchValue('');
      setResults([]);
    }
  }, [isSearch]);

  useEffect(() => {
    if (searchQuery === '') {
      setResults([]);
    } else {
      getSearchResult(searchQuery).then((response) => {
        setResults(response.data);
      });
    }
  }, [searchQuery]);

  useEffect(() => {
    if (prevPath && prevPath !== router.asPath) {
      setSearch(false);
    }
  }, [router.asPath, prevPath]);

  return (
    <SearchBlock>
      <SearchBtn
        ref={searchBtnRef}
        onClick={toggleSearch}
        themePage={themePage}
      >
        {isSearch ? <CloseIcon /> : <SearchIcon />}
      </SearchBtn>
      <SearchWrapper isSearch={isSearch} themePage={themePage}>
        <SearchInput
          isSearch={isSearch}
          autoComplete="off"
          type="text"
          name="search"
          placeholder="Найти"
          ref={searchInputRef}
          onBlur={() =>
            setTimeout(() => {
              if (!isSearch) return;
              setSearch(false);
            }, 100)
          }
          onChange={(e) => dispatch(queryAdded(e.target.value))}
          onKeyDown={(event) => handlerKeyDown(event)}
          value={searchQuery}
        />
      </SearchWrapper>
      <ResultBlock isFocus={isSearch}>
        <ResultBlockInner>
          <ResultTop>
            <ResultHeader>
              {results.length === 0
                ? 'Популярные поисковые запросы'
                : 'Возможно вы ищите ...'}
            </ResultHeader>
          </ResultTop>
          <ResultList>
            {results.length !== 0
              ? results.slice(0, 4).map((resultsItem, index) => {
                  return (
                    <ResultItem key={index}>
                      <ResultItemLink to={resultsItem.url}>
                        {resultsItem.title}: {resultsItem.name}
                      </ResultItemLink>
                    </ResultItem>
                  );
                })
              : searchHintsList.map((searchHintsItem, index) => {
                  return (
                    <ResultItem key={index}>
                      <ResultItemLink to={`/search?query=${searchHintsItem}`}>
                        {searchHintsItem}
                      </ResultItemLink>
                    </ResultItem>
                  );
                })}
          </ResultList>
        </ResultBlockInner>
      </ResultBlock>
    </SearchBlock>
  );
}

const SearchBlock = styled.div``;

const SearchBtn = styled.button<{ themePage: StringField }>`
  height: 62px;
  width: 104px;

  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 2;
  background: ${(props) =>
    props.themePage ? `${colors.black500}` : `${colors.orange}`};
  transition: all 0.3s linear;

  svg {
    width: 16px;
    height: 16px;
    pointer-events: none;
  }

  ${media.laptop(css`
    width: 100px;
  `)}

  &:hover {
    background: ${(props) =>
      props.themePage ? `${colors.black500}` : `${colors.orangeHover}`};
  }
`;

const SearchWrapper = styled.div<{ isSearch: boolean; themePage: StringField }>`
  position: absolute;
  content: '';
  top: 0;
  bottom: 0;
  right: 0;
  padding: 0 64px 0 40px;
  background: ${(props) =>
    props.themePage ? `${colors.black500}` : `${colors.orange}`};
  max-width: 0;
  overflow: hidden;
  width: 100%;
  transition: all 0.6s ease-in 0.2s;

  ${(props) =>
    props.isSearch
      ? css`
          max-width: calc(100% + 40px);
          width: calc(100% + 40px);
          transition: all 0.6s linear;
        `
      : css`
          max-width: 0;
          width: 100%;
          transition: all 0.6s ease-in 0.2s;
        `}
`;

const SearchInput = styled.input<{ isSearch: boolean }>`
  border: none;
  background: transparent;
  width: 100%;
  padding: 7px 0;
  font-weight: bold;
  font-size: 36px;
  line-height: 48px;
  color: ${colors.white};

  ${media.laptop(css`
    font-size: 24px;
    line-height: 32px;
    padding: 15px 0;
  `)}

  &::placeholder {
    color: ${colors.white50};

    ${media.laptop(css`
      font-size: 24px;
      line-height: 32px;
    `)}
  }
`;

const ResultBlockInner = styled.div`
  max-width: 1600px;
  margin: 0 auto;
  width: 100%;

  @media (max-width: 1750px) {
    max-width: 1328px;
  }

  ${media.laptop(css`
    max-width: 994px;
  `)}
`;

const ResultTop = styled.div`
  margin-bottom: 50px;

  ${media.laptop(css`
    margin-bottom: 30px;
  `)}
`;
const ResultHeader = styled.span`
  font-weight: 500;
  font-size: 30px;
  line-height: 36px;
  color: ${colors.grayLight};

  ${media.laptop(css`
    font-size: 20px;
    line-height: 26px;
  `)}
`;

const ResultList = styled.div``;

const ResultItem = styled.div`
  font-weight: 500;
  font-size: 30px;
  line-height: 36px;
  color: ${colors.black};
  margin-bottom: 30px;
  cursor: pointer;
  transition: all 0.3s linear;

  ${media.laptop(css`
    margin-bottom: 24px;
    font-size: 20px;
    line-height: 26px;
  `)}

  &:hover {
    opacity: 0.7;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

const ResultItemLink = styled(Link)`
  display: block;
`;

const ResultBlock = styled.div<{ isFocus?: boolean }>`
  position: absolute;
  top: 0;
  right: -80px;
  width: 100vw;
  padding: 88px 0 60px;
  background: ${colors.white};
  opacity: 0;
  visibility: hidden;
  transition: pointer-events 0s, 0.8s;
  z-index: 1;
  pointer-events: none;
  min-height: 390px;

  ${media.laptop(css`
    padding: 68px 0 40px;
    right: 0;
  `)}

  ${(props) =>
    !props.isFocus
      ? css``
      : css`
          visibility: visible;
          opacity: 1;
          top: 100%;
          pointer-events: auto;
        `};
`;

export default HeaderSearch;
