import React from 'react';
import styled, { css } from 'styled-components';

import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';
import { ReactComponent as FacebookLogo } from '@/assets/svg/facebook.svg';
import { ReactComponent as VkontakteLogo } from '@/assets/svg/vk.svg';
import { ReactComponent as InstagramLogo } from '@/assets/svg/instagram.svg';
import { ReactComponent as YouTubeLogo } from '@/assets/svg/youtube.svg';
import { ReactComponent as TikTokLogo } from '@/assets/svg/tiktok.svg';
import { ReactComponent as SocialStar } from '@/assets/svg/social-star.svg';
import useSettingItem from '@/hooks/useSettingItem';

function SocialBlock() {
  const facebookUrl = useSettingItem('FOOTER_SOCIAL_FACEBOOK_URL');
  const vkontakteUrl = useSettingItem('FOOTER_SOCIAL_VKONTAKTE_URL');
  const instagramUrl = useSettingItem('FOOTER_SOCIAL_INSTAGRAM_URL');
  const youtubeUrl = useSettingItem('FOOTER_SOCIAL_YOUTUBE_URL');
  const tiktokUrl = useSettingItem('FOOTER_SOCIAL_TIKTOK_URL');

  return (
    <SocialList>
      {facebookUrl ? (
        <SocialItem>
          <SocialBackground>
            <SocialStar />
          </SocialBackground>
          <SocialLink href={facebookUrl} target="_blank" />
          <FacebookLogo />
        </SocialItem>
      ) : null}

      {vkontakteUrl ? (
        <SocialItem>
          <SocialBackground>
            <SocialStar />
          </SocialBackground>
          <SocialLink href={vkontakteUrl} target="_blank" />
          <VkontakteLogo />
        </SocialItem>
      ) : null}

      {instagramUrl ? (
        <SocialItem>
          <SocialBackground>
            <SocialStar />
          </SocialBackground>
          <SocialLink href={instagramUrl} target="_blank" />
          <InstagramLogo />
        </SocialItem>
      ) : null}

      {youtubeUrl ? (
        <SocialItem>
          <SocialBackground>
            <SocialStar />
          </SocialBackground>
          <SocialLink href={youtubeUrl} target="_blank" />
          <YouTubeLogo />
        </SocialItem>
      ) : null}

      {tiktokUrl ? (
        <SocialItem isTikTok={true}>
          <SocialBackground>
            <SocialStar />
          </SocialBackground>
          <SocialLink href={tiktokUrl} target="_blank" />
          <TikTokLogo />
        </SocialItem>
      ) : null}
    </SocialList>
  );
}

const SocialList = styled.ul`
  display: flex;
  margin: 0 -5px;

  ${media.laptop(css`
    margin: 16px -3px 0;
  `)}

  ${media.tabletSmall(css`
    margin: 16px -8px 0;
  `)}
  
    
  ${media.mobile(css`
    margin: 22px -15px 0;
  `)}
`;
const SocialBackground = styled.div`
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  svg {
    width: 100% !important;
    height: 100% !important;
    opacity: 0.15;
    transition: all 0.45s ease-in-out;
  }
`;

const SocialItem = styled.li<{ isTikTok?: boolean }>`
  margin: 0 5px;
  width: 78px;
  height: 78px;
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;
  color: ${colors.white};
  transition: all 0.5s ease-in-out;

  &:hover {
    transform: rotate(10deg);

    ${SocialBackground} {
      svg {
        opacity: 0.25;
        transform: rotate(0);
      }
    }
  }

  ${media.laptop(css`
    width: 52px;
    height: 52px;
    margin: 0 3px;
  `)}

  ${media.tabletSmall(css`
    width: 60px;
    height: 60px;
    margin: 0 8px;
  `)}
  
  ${media.mobile(css`
    width: 57px;
    height: 57px;
    margin: 0 15px;
  `)}

  ${(props) =>
    props.isTikTok
      ? css`
          svg {
            width: 20px;
            height: 20px;
            transition: all 0.3s linear;

            ${media.laptop(css`
              width: 20px;
              height: 20px;
            `)}

            ${media.tabletSmall(css`
              width: 20px;
              height: 20px;
            `)}
          }
        `
      : css`
          svg {
            width: 36px;
            height: 36px;
            transition: all 0.3s linear;

            ${media.laptop(css`
              width: 24px;
              height: 24px;
            `)}

            ${media.tabletSmall(css`
              width: 36px;
              height: 32px;
            `)}
          }
        `}
`;

const SocialLink = styled.a`
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
`;

export default SocialBlock;
