import React from 'react';
import styled, { css } from 'styled-components';
import { useSelect } from 'downshift';

import { ReactComponent as DownIcon } from '@/assets/svg/arrow-down.svg';
import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';
import { ReactComponent as ArrowIcon } from '@/assets/svg/arrow-select.svg';

export interface OptionType {
  label: string;
  value: string;
}

interface Props {
  placeholder: string;
  options: Array<OptionType>;
  value: OptionType;
  onChange: (selectedOption: OptionType | null) => void;
  isHeaderSelect?: boolean;
}

function DropDownSelect({
  placeholder,
  options,
  value,
  onChange,
  isHeaderSelect,
}: Props) {
  const {
    isOpen,
    selectedItem,
    getToggleButtonProps,
    getMenuProps,
    highlightedIndex,
    getItemProps,
  } = useSelect({
    selectedItem: value,
    items: options,
    onSelectedItemChange: (changes) => {
      onChange(changes.selectedItem ?? null);
    },
  });

  return (
    <DropDown isHeaderSelect={isHeaderSelect}>
      <DropDownDisplay
        type="button"
        isOpen={isOpen}
        isHeaderSelect={isHeaderSelect}
        {...getToggleButtonProps()}
      >
        <DropDownDisplayText>
          {selectedItem?.label || placeholder || 'DropDownSelect...'}
        </DropDownDisplayText>
        <DropDownDisplayIcon />
      </DropDownDisplay>

      <DropDownList isOpen={isOpen} {...getMenuProps()}>
        <DropDownListInner>
          {options?.length === 0 ? (
            <NoOptions>No options</NoOptions>
          ) : (
            options.map((option, index) => (
              <DropDownListItem
                isHeaderSelect={isHeaderSelect}
                isActive={selectedItem?.value === option.value}
                style={
                  highlightedIndex === index
                    ? { backgroundColor: '#bde4ff' }
                    : {}
                }
                key={`${option.value}${index}`}
                {...getItemProps({ item: option, index })}
              >
                <DropDownListItemText
                  isActive={selectedItem?.value === option.value}
                >
                  {selectedItem?.value === option.value && <ArrowIcon />}
                  {option.label}
                </DropDownListItemText>
              </DropDownListItem>
            ))
          )}
        </DropDownListInner>
      </DropDownList>
    </DropDown>
  );
}

export default DropDownSelect;

const DropDownDisplayIcon = styled(DownIcon)`
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  transition: all 0.3s linear, transform 0.3s ease 0s;
  width: 12px;
  height: 18px;
  color: ${colors.black1000};
`;

const DropDownDisplayText = styled.span`
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  color: ${colors.black1000};
  padding-right: 27px;
  transition: all 0.3s linear;

  ${media.laptop(css`
    font-size: 16px;
    line-height: 24px;
    padding-right: 23px;
  `)}

  ${media.mobile(css`
    font-size: 12px;
    line-height: 20px;
    padding-right: 21px;
  `)}
`;

const DropDownDisplay = styled.button<{
  isOpen: boolean;
  isHeaderSelect?: boolean;
}>`
  position: relative;
  display: flex;
  align-items: center;

  width: 100%;
  height: 26px;
  outline: none;

  ${({ isOpen }) =>
    isOpen &&
    css`
      ${DropDownDisplayIcon} {
        transform: translateY(-50%) rotate(180deg);
      }
    `}

  ${(props) =>
    !props.isHeaderSelect
      ? css`
          &:hover {
            ${DropDownDisplayIcon}, ${DropDownDisplayText} {
              color: ${colors.orange};
            }
          }
        `
      : ''}
`;

const DropDownList = styled.div<{ isOpen: boolean }>`
  position: absolute;
  overflow: hidden;
  top: calc(100% + 16px);
  left: -18%;
  z-index: 99;
  max-height: 280px;
  min-width: 420px;

  padding: 20px 8px 20px 16px;
  margin: 0;
  list-style: none;
  outline: none;

  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  background: ${colors.white};
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.12), 0 18px 88px rgba(0, 0, 0, 0.14);

  ${media.laptop(css`
    min-width: 278px;
    max-height: 294px;
    padding: 16px 4px 16px 8px;
  `)}

  ${media.tabletOnly(css`
    left: -10px;
  `)}

  ${media.mobileLarge(css`
    position: fixed;
    top: 340px;
    left: 20px;
    max-width: calc(100vw - 40px);
    min-width: calc(100vw - 40px);
    max-height: 294px;
    padding: 16px 4px 16px 8px;
  `)}
`;

const DropDownListInner = styled.ul`
  max-height: 230px;
  overflow-y: auto;
  scrollbar-color: ${colors.orange} transparent;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 0.375rem;

    &-track {
      background-color: transparent;
    }

    &-thumb {
      background-color: ${colors.orange};
      &:hover {
        background-color: ${colors.orangeHover};
      }
    }
  }

  ${media.laptop(css`
    max-height: 262px;
  `)}
`;

const DropDownListItemText = styled.span<{ isActive: boolean }>`
  user-select: none;
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  color: ${({ isActive }) =>
    isActive ? `${colors.orange}` : `${colors.black}`};
  transition: all 0.3s linear;

  svg {
    position: absolute;
    top: 45%;
    left: 0;
    transform: translateY(-50%);
    width: 30px;
    height: 30px;

    ${media.laptop(css`
      width: 24px;
      height: 24px;
    `)}

    ${media.tabletOnly(css`
      width: 22px;
      height: 22px;
    `)}
  }

  ${media.laptop(css`
    font-size: 16px;
    line-height: 24px;
  `)}

  ${media.tabletOnly(css`
    line-height: 22px;
  `)}

  ${media.mobile(css`
    font-size: 12px;
    line-height: 20px;
  `)}
`;

const DropDownListItem = styled.li<{
  isActive: boolean;
  isHeaderSelect?: boolean;
}>`
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 10px 20px 10px 40px;
  background: transparent !important;
  transition: all 0.3s linear;

  ${media.laptop(css`
    padding: 5px 20px 5px 32px;
  `)}

  ${media.tabletOnly(css`
    padding: 8px 20px 8px 32px;
  `)}

  &:hover {
    ${DropDownListItemText} {
      color: ${colors.orange};
    }
  }

  ${(props) =>
    props.isHeaderSelect
      ? props.isActive
        ? css`
            display: none;
          `
        : ''
      : ''}
`;

const NoOptions = styled.p`
  display: flex;
  align-items: center;

  margin: 0;
  padding: 10px 12px;

  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  color: ${colors.black};

  ${media.laptop(css`
    font-size: 16px;
    line-height: 24px;
  `)}

  ${media.tabletOnly(css`
    line-height: 22px;
  `)}

  ${media.mobile(css`
    font-size: 12px;
    line-height: 20px;
  `)}
`;

const DropDown = styled.div<{ isHeaderSelect?: boolean }>`
  position: relative;

  ${(props) =>
    props.isHeaderSelect
      ? css`
          ${DropDownDisplay} {
            height: 62px;
          }

          ${DropDownDisplayText} {
            padding-right: 21px;
            margin-right: 6px;

            ${media.laptop(css`
              padding-right: 6px;
              margin-right: 11px;
            `)}
          }

          ${DropDownList} {
            top: 100%;
            left: -38px;
            right: -38px;
            min-width: auto;
            padding: 0;
            background: ${colors.black500};
            max-height: 290px;

            ${media.laptop(css`
              right: -44px;
              max-height: 248px;
            `)}
          }

          ${DropDownListInner} {
            max-height: 257px;
            scrollbar-color: transparent ${colors.yellow200};

            &::-webkit-scrollbar {
              &-thumb {
                background-color: ${colors.yellow200};
                &:hover {
                  background-color: ${colors.yellowHover};
                }
              }
            }

            ${media.laptop(css`
              max-height: 262px;
            `)}
          }

          ${DropDownListItem} {
            padding: 19px 19px 19px 38px;

            &:hover {
              background: ${colors.yellow200}!important;
              ${DropDownListItemText} {
                color: ${colors.black};
              }
            }
          }
          ${DropDownListItemText} {
            color: ${colors.white};
          }
        `
      : ''}
`;
