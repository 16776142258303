import React from 'react';
import styled, { css } from 'styled-components';
import { useRouter } from 'next/router';

import { MenuItemType } from '@tager/web-modules';

import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';
import Link from '@/components/Link';

type Props = {
  menuList: Array<MenuItemType>;
  isFlex?: boolean;
};

function FooterMenu({ menuList, isFlex }: Props) {
  const router = useRouter();

  return (
    <MenuList isFlex={isFlex}>
      {menuList.map(({ label, link }, index) => {
        return (
          <MenuItem key={index} isActive={router.asPath === link}>
            <MenuLink to={link ?? '#'}>{label}</MenuLink>
          </MenuItem>
        );
      })}
    </MenuList>
  );
}

const MenuItem = styled.li<{ isActive: boolean }>`
  display: block;
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  color: ${colors.grayLight};
  padding: 10px 0;
  transition: all 0.3s linear;

  &:first-child {
    margin-top: 0;
  }

  &:hover {
    color: ${colors.gray50};
  }

  ${media.laptop(css`
    font-size: 16px;
    line-height: 24px;
    padding: 8px 0;
  `)}

  ${media.tablet(css`
    font-size: 16px;
    line-height: 22px;
  `)}
  
  ${media.mobile(css`
    font-size: 12px;
    line-height: 20px;
    margin-top: 12px;
    padding: 0;

    &:first-child {
      margin-top: 0;
    }
  `)}

  ${(props) =>
    props.isActive
      ? css`
          transition: all 0s linear;
          color: ${colors.gray50};
          pointer-events: none;
        `
      : ''}
`;

const MenuLink = styled(Link)``;

const MenuList = styled.ul<{ isFlex?: boolean }>`
  max-width: 300px;
  width: 100%;

  @media (max-width: 1900px) {
    max-width: 240px;
  }
  ${media.laptop(css`
    max-width: 200px;
    margin-top: 17px;
  `)}

  ${media.tablet(css`
    max-width: 200px;
    margin-top: 17px;
  `)}
  
    ${media.tabletSmall(css`
    max-width: 100%;
    margin-top: 0;
  `)}
  
${(props) =>
    props.isFlex
      ? css`
          display: flex;
          flex-wrap: wrap;
          margin: -10px;

          ${MenuItem} {
            margin: 10px;
            padding: 0;
          }
        `
      : ''}
`;

export default FooterMenu;
