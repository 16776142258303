import React from 'react';
import styled, { css } from 'styled-components';
import { useRouter } from 'next/router';

import Link from '@/components/Link';
import { media } from '@/utils/mixin';
import { colors } from '@/constants/theme';
import { StringField } from '@/typings/model';

type TMenuItem = { label: string; link: string };

interface Props {
  themePage: StringField;
  menu: TMenuItem[];
  onCookiesClick?: () => void;
}

function FooterBottomMenu({ menu, themePage, onCookiesClick }: Props) {
  const router = useRouter();

  const handleMenuItemClick = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    link: string
  ) => {
    if (link === '#') {
      e.preventDefault();
      if (onCookiesClick) {
        onCookiesClick();
      }
      return;
    }
  };

  return (
    <>
      {menu && (
        <Container>
          {menu.map(({ link, label }: TMenuItem) => (
            <MenuLink
              key={link}
              to={link}
              themePage={themePage}
              isActive={router.asPath === link}
              onClick={(e) => {
                handleMenuItemClick(e, link);
              }}
            >
              {label}
            </MenuLink>
          ))}
        </Container>
      )}
    </>
  );
}

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 11px;

  ${media.mobileLarge(css`
    margin-bottom: 7px;
  `)}
`;

const MenuLink = styled(Link)<{ themePage: StringField }>`
  color: ${colors.gray110};
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  text-decoration-line: underline;
  margin-right: 24px;
  white-space: nowrap;
  cursor: pointer;

  &:hover {
    color: ${(props) =>
      props.themePage === '#E4FF41'
        ? `${props.themePage}`
        : props.themePage === '#CD2544'
        ? `${props.themePage}`
        : `${colors.orange}`};
    text-decoration-line: underline;
  }

  ${({ isActive, themePage }) =>
    isActive
      ? css`
          color: ${themePage === '#E4FF41'
            ? `${themePage}`
            : themePage === '#CD2544'
            ? `${themePage}`
            : `${colors.orange}`};
          text-decoration-line: underline;
        `
      : ''}

  ${media.tabletLarge(css`
    font-size: 16px;
    line-height: 26px;
  `)}

  ${media.mobileLarge(css`
    font-size: 12px;
    line-height: 26px;
    margin-right: 12px;
  `)}
`;

export default FooterBottomMenu;
