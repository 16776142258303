import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import styled, { css } from 'styled-components';

import { usePrevious } from '@tager/web-core';

import { queryAdded, selectQuery } from '@/store/reducers/tager/search';
import { SearchResultItemType } from '@/typings/model';
import useSettingItem from '@/hooks/useSettingItem';
import { getSearchResult } from '@/services/requests';
import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';
import Link from '@/components/Link';

interface Props {
  searchActive: boolean;
  setSearchActive: (value: boolean) => void;
}

function HeaderSearchMobile({ searchActive, setSearchActive }: Props) {
  const [searchValue, setSearchValue] = useState<string>('');
  const [results, setResults] = useState<Array<SearchResultItemType>>([]);
  const searchInputRef = useRef<HTMLInputElement>(null);

  const searchHintsString = useSettingItem('SEARCH_HINTS');

  const searchQuery = useSelector(selectQuery);
  const dispatch = useDispatch();
  const router = useRouter();
  const prevPath = usePrevious(router.asPath);

  const searchHintsList = searchHintsString
    ? searchHintsString.split('\n')
    : [];

  useEffect(() => {
    if (!searchInputRef.current) return;

    if (searchActive) {
      searchInputRef.current.focus();
      searchInputRef.current.value = '';
      setSearchValue('');
      setResults([]);
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [searchActive]);

  useEffect(() => {
    if (searchQuery === '') {
      setResults([]);
    } else {
      getSearchResult(searchQuery).then((response) => {
        setResults(response.data);
      });
    }
  }, [searchQuery]);

  useEffect(() => {
    if (prevPath && prevPath !== router.asPath) {
      setSearchActive(false);
    }
  }, [router.asPath, prevPath]);

  function handlerKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.keyCode === 13 && !!searchQuery.trim().length) {
      router.push(`/search?query=${searchQuery}`);
    }
  }

  return (
    <SearchBlock>
      <SearchInner isSearch={searchActive}>
        <SearchInput
          isSearch={searchActive}
          autoComplete="off"
          type="text"
          name="search"
          placeholder="Найти"
          ref={searchInputRef}
          onBlur={() =>
            setTimeout(() => {
              if (!searchActive) return;
              setSearchActive(false);
            }, 100)
          }
          onChange={(e) => dispatch(queryAdded(e.target.value))}
          onKeyDown={(event) => handlerKeyDown(event)}
          value={searchQuery}
        />
      </SearchInner>
      <ResultBlock isFocus={searchActive}>
        <ResultBlockInner>
          <ResultTop>
            <ResultHeader>
              {results.length === 0
                ? 'Популярные поисковые запросы'
                : 'Возможно вы ищите ...'}
            </ResultHeader>
          </ResultTop>
          <ResultList>
            {results.length !== 0
              ? results.slice(0, 4).map((resultsItem, index) => {
                  return (
                    <ResultItem key={index}>
                      <ResultItemLink to={resultsItem.url}>
                        {resultsItem.title}: {resultsItem.name}
                      </ResultItemLink>
                    </ResultItem>
                  );
                })
              : searchHintsList.map((searchHintsItem, index) => {
                  return (
                    <ResultItem key={index}>
                      <ResultItemLink to={`/search?query=${searchHintsItem}`}>
                        {searchHintsItem}
                      </ResultItemLink>
                    </ResultItem>
                  );
                })}
          </ResultList>
        </ResultBlockInner>
      </ResultBlock>
    </SearchBlock>
  );
}

const SearchBlock = styled.div`
  display: none;
  position: absolute;
  content: '';
  top: 0;
  bottom: 0;
  left: 116px;
  right: 0;

  ${media.tablet(css`
    display: flex;
    justify-content: flex-end;
  `)}

  ${media.mobile(css`
    left: 100px;
  `)}
`;

const SearchInner = styled.div<{ isSearch: boolean }>`
  padding: 0 64px 0 40px;
  background: ${colors.black500};
  max-width: 0;
  overflow: hidden;
  width: 100%;
  transition: all 0.4s ease-in 0.2s;

  ${media.tablet(css`
    padding: 0 70px 0 0;
  `)}

  ${media.mobile(css`
    padding-right: 54px;
  `)}

  ${(props) =>
    props.isSearch
      ? css`
          max-width: 100%;
          width: 100%;
          transition: all 0.4s linear 0.2s;
        `
      : css`
          max-width: 0;
          width: 100%;
          transition: all 0.4s ease-in 0.2s;
        `};
`;

const SearchInput = styled.input<{ isSearch: boolean }>`
  border: none;
  background: transparent;
  width: 100%;
  padding: 7px 0;
  font-weight: bold;
  font-size: 36px;
  line-height: 48px;
  color: ${colors.white};

  ${media.laptop(css`
    font-size: 24px;
    line-height: 32px;
    padding: 15px 0;
  `)}

  ${media.tablet(css`
    padding: 22px 0 22px 20px;
    font-size: 20px;
    line-height: 26px;
  `)}
  
  ${media.mobile(css`
    padding: 18px 0 18px 10px;
    font-size: 16px;
    line-height: 20px;
  `)}

  &::placeholder {
    color: ${colors.white50};

    ${media.laptop(css`
      font-size: 24px;
      line-height: 32px;
    `)}

    ${media.tablet(css`
      font-size: 20px;
      line-height: 26px;
    `)}
    
    ${media.mobile(css`
      font-size: 16px;
      line-height: 20px;
    `)}
  }
`;

const ResultBlockInner = styled.div`
  max-width: 1600px;
  margin: 0 auto;
  width: 100%;

  @media (max-width: 1750px) {
    max-width: 1328px;
  }

  ${media.laptop(css`
    max-width: 994px;
  `)}
`;

const ResultTop = styled.div`
  margin-bottom: 50px;

  ${media.laptop(css`
    margin-bottom: 30px;
  `)}

  ${media.tablet(css`
    margin-bottom: 26px;
  `)}
`;
const ResultHeader = styled.span`
  font-weight: 500;
  font-size: 30px;
  line-height: 36px;
  color: ${colors.grayLight};

  ${media.laptop(css`
    font-size: 20px;
    line-height: 26px;
  `)}

  ${media.tablet(css`
    font-size: 18px;
    line-height: 22px;
  `)}
  
  ${media.mobile(css`
    font-size: 16px;
    line-height: 20px;
  `)}
`;

const ResultList = styled.div``;

const ResultItem = styled.div`
  font-weight: 500;
  font-size: 30px;
  line-height: 36px;
  color: ${colors.black};
  margin-bottom: 30px;
  cursor: pointer;
  transition: all 0.4s linear 0.2s, margin-bottom 0s;

  ${media.laptop(css`
    margin-bottom: 24px;
    font-size: 20px;
    line-height: 26px;
  `)}

  ${media.tablet(css`
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 20px;
  `)}
  
    ${media.mobile(css`
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 18px;
  `)}
  
  
  ${media.mobile(css``)}

  &:hover {
    opacity: 0.7;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

const ResultItemLink = styled(Link)`
  display: block;

  ${media.tablet(css`
    cursor: default;
  `)}
`;

const ResultBlock = styled.div<{ isFocus?: boolean }>`
  position: absolute;
  top: 0;
  right: -80px;
  width: 100vw;
  padding: 88px 0 60px;
  background: ${colors.white};
  opacity: 0;
  visibility: hidden;
  transition: pointer-events 0s, 0.4s linear 0.2s;
  z-index: 12;
  pointer-events: none;
  min-height: 390px;

  ${media.laptop(css`
    padding: 68px 0 40px;
    right: 0;
  `)};

  ${media.tablet(css`
    min-height: 300px;
    padding: 74px 20px 30px;

    &:before {
      position: absolute;
      content: '';
      top: 100%;
      left: 0;
      width: 100vw;
      height: calc(100vh - 300px);
      z-index: 11;
      background: ${colors.black50};
      pointer-events: none;
      opacity: 0;
      visibility: hidden;
      transition: pointer-events 0s, 0.3s linear 0.2s;
    }
  `)};

  ${media.mobile(css`
    min-height: 270px;
    padding: 56px 20px 30px;
  `)};

  ${(props) =>
    !props.isFocus
      ? css``
      : css`
          visibility: visible;
          opacity: 1;
          top: 100%;
          pointer-events: auto;

          ${media.tablet(css`
            &:before {
              visibility: visible;
              opacity: 1;
              pointer-events: auto;
            }
          `)};
        `};
`;

export default HeaderSearchMobile;
