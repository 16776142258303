import React from 'react';
import styled, { css } from 'styled-components';

import { colors } from '@/constants/theme';
import { ReactComponent as AccountIcon } from '@/assets/svg/account.svg';
import { media } from '@/utils/mixin';

type Props = {
  label: string;
  url: string;
};

function AccountBtn({ url, label }: Props) {
  return (
    <BtnContent>
      <BtnUrl href={url} target={'_blank'} />
      <BtnIcon>
        <AccountIcon />
      </BtnIcon>
      <BtnLabel>{label}</BtnLabel>
    </BtnContent>
  );
}

const BtnContent = styled.button`
  position: relative;
  display: flex;
  align-items: center;
  padding: 10px 53px 10px 49px;
  color: ${colors.white40};
  transition: all 0.3s ease-in-out;
  background: ${colors.white6};

  ${media.laptop(css`
    padding: 10px 49px;
  `)}
  ${media.tablet(css`
    padding: 12px;
    background: transparent;
  `)}
  ${media.mobile(css`
    padding: 12px;
  `)}
  &:hover {
    color: ${colors.white};
  }
`;

const BtnUrl = styled.a`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 2;
`;

const BtnIcon = styled.div`
  width: 16px;
  height: 16px;
  margin-right: 10px;

  ${media.tabletOnly(css`
    width: 20px;
    height: 20px;
  `)}
  svg {
    width: 100%;
    height: 100%;
    pointer-events: none;
  }
`;

const BtnLabel = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  pointer-events: none;

  ${media.laptop(css`
    font-size: 12px;
    line-height: 18px;
  `)}

  ${media.tabletOnly(css`
    font-size: 16px;
    line-height: 20px;
  `)}

  ${media.mobile(css`
    font-size: 12px;
    line-height: 20px;
  `)}
`;

export default AccountBtn;
