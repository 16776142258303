import React from 'react';
import styled, { css } from 'styled-components';

import { MenuItemType } from '@tager/web-modules';

import { media } from '@/utils/mixin';

import FooterBlock from './FooterBlock';
import FooterMenu from './FooterMenu';

type Props = {
  navbarList: Array<MenuItemType>;
};

function FooterNavbar({ navbarList }: Props) {
  return (
    <NavbarList>
      {navbarList.map(({ label, children }, index) => {
        return (
          <NavbarListCell key={index}>
            <FooterBlock title={label}>
              <FooterMenu menuList={children} />
            </FooterBlock>
          </NavbarListCell>
        );
      })}
    </NavbarList>
  );
}

const NavbarList = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 1380px;
  margin: 0 auto;
`;

const NavbarListCell = styled.div`
  margin-right: 48px;

  ${media.tablet(css`
    margin-right: 35px;
  `)}

  &:last-child {
    margin-right: 0;
  }
`;

export default FooterNavbar;
