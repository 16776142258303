import React, { useRef, useState } from 'react';
import styled from 'styled-components';

import { MenuItemType } from '@tager/web-modules';
import { useMedia } from '@tager/web-core';

import { OptionType } from '@/components/DropDownSelect/DropDownSelect';
import { StringField } from '@/typings/model';
import { breakpoints } from '@/constants/theme';

import Preloader from '../Preloader';

import Header from './components/Header';
import Footer from './components/Footer';
import { LayoutProvider } from './Layout.hooks';
import FooterMobile from './components/FooterMobile';

type Props = {
  children?: React.ReactNode;
  isPreloaderHidden?: boolean;
  onCookiesClick?: () => void;
};

function Layout({ children, isPreloaderHidden, onCookiesClick }: Props) {
  const isMobile = useMedia(`(max-width: ${breakpoints.tabletSmall - 1}px)`);

  const heroRef = useRef<HTMLDivElement>(null);
  const [menuStore, setMenuStore] = useState<Array<MenuItemType>>([]);
  const [
    startPagePathWithFooterInformation,
    setStartPagePathWithFooterInformation,
  ] = useState<StringField>('');
  const [footerInformation, setFooterInformation] = useState<StringField>('');
  const [themePage, setThemePage] = useState<StringField>('');
  const [activeStoreName, setActiveStoreName] = useState<OptionType>({
    label: '',
    value: '',
  });

  function handlerMenuStore(menuList: Array<MenuItemType>) {
    setMenuStore(menuList);
  }

  function handlerActiveStoreName(storeName: OptionType) {
    setActiveStoreName(storeName);
  }

  return (
    <LayoutProvider
      value={{
        heroRef: heroRef,
        footerInformation,
        setFooterInformation,
        themePage,
        setThemePage,
        menuStore,
        setMenuStore: handlerMenuStore,
        activeStoreName,
        setActiveStoreName: handlerActiveStoreName,
        startPagePathWithFooterInformation,
        setStartPagePathWithFooterInformation,
      }}
    >
      <Container>
        <Preloader hidden={isPreloaderHidden} />
        <Header onCookiesClick={onCookiesClick} />
        <Main>{children}</Main>
        {isMobile ? (
          <FooterMobile onCookiesClick={onCookiesClick} />
        ) : (
          <Footer onCookiesClick={onCookiesClick} />
        )}
      </Container>
    </LayoutProvider>
  );
}

const Container = styled.div`
  background-color: #fff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Main = styled.main`
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
`;

export default Layout;
